import {IWixWindowViewMode} from '@wix/native-components-infra/dist/es/src/types/types'
import classNames from 'classnames'
import React from 'react'
import {viewerDataHooks as DH} from '@wix/wix-events-data-hooks'
import {ErrorPage} from '../../../../../commons/components/error-page'
import {RuntimeContext} from '../../../../../commons/components/runtime-context/runtime-context'
import {Direction} from '../../../../../commons/constants/html'
import {DatesProvider} from '../../../../../commons/hooks/dates'
import {WidgetStateContext, WidgetActionsContext} from '../../hooks/state-provider'
import {AppLoaded} from '../app-loaded'
import {Layout} from '../layout'
import {isPreview} from '../../../../../commons/selectors/environment'
import {SettingsUpdatedActionType} from '../../actions/sdk'
import {OBIntro, updateSiteWithObData} from '../../utils/ob-intro'
import {navigateToPage} from '../../../Settings/actions/external-navigation'
import {isButtonLayout} from '../../selectors/settings'
import s from './app.scss'
import {AppProps} from './interfaces'

export class App extends React.Component<AppProps> {
  componentDidMount() {
    if (this.props.sdk?.Wix) {
      this.props.sdk.Wix.addEventListener(this.props.sdk.Wix.Events.SETTINGS_UPDATED, this.updateSettings)
      this.props.sdk.Wix.addEventListener(this.props.sdk.Wix.Events.STYLE_PARAMS_CHANGE, this.updateStyle)
      this.props.sdk.Wix.addEventListener(this.props.sdk.Wix.Events.EDIT_MODE_CHANGE, this.onEditModeChange)
      this.props.sdk.Wix.addEventListener(this.props.sdk.Wix.Events.SITE_PUBLISHED, this.onSitePublished)
      this.props.sdk.Wix.Data.Public.get(
        'OB_INTRO_DATA',
        null,
        (this.onPublicDataChange = () =>
          addEventListener(this.props.sdk.Wix.Events.PUBLIC_DATA_CHANGED as any, this.onPublicDataChange)),
        () => null,
      )
    }
  }

  componentDidUpdate(prevProps: AppProps) {
    const {actions, host, state} = this.props
    if (actions && host && state) {
      if (isPreview(state) && host.formFactor !== prevProps.host.formFactor) {
        actions.setFormFactor(host.formFactor)
      }
    }
  }

  componentWillUnmount() {
    if (this.props.sdk?.Wix) {
      // @ts-expect-error
      this.props.sdk.Wix.removeEventListener(this.props.sdk.Wix.Events.SETTINGS_UPDATED, this.updateSettings)
      // @ts-expect-error
      this.props.sdk.Wix.removeEventListener(this.props.sdk.Wix.Events.STYLE_PARAMS_CHANGE, this.updateStyle)
      // @ts-expect-error
      this.props.sdk.Wix.removeEventListener(this.props.sdk.Wix.Events.EDIT_MODE_CHANGE, this.onEditModeChange)
    }
  }

  updateStyle = styleParams => {
    console.log('updating style from WIX SDK')
    this.props.actions.updateStyle(styleParams)
  }

  onPublicDataChange = (data: OBIntro) => {
    if (data.OB_INTRO_DATA) {
      updateSiteWithObData(data, this.props.actions, this.props.state, this.props.sdk?.Wix)
    }
  }

  updateSettings = action => {
    switch (action.type) {
      case SettingsUpdatedActionType.NAVIGATE_TO_PAGE:
      case SettingsUpdatedActionType.NAVIGATE_TO_PAGE_PENDING:
        if (navigateToPage.pending.match(action)) {
          if (this.props.sdk?.Wix) {
            this.props.sdk.Wix.Data.Public.set(action.type, action.meta.arg, {scope: 'APP'}, undefined, undefined)
          }
        } else {
          if (this.props.sdk?.Wix) {
            this.props.sdk.Wix.Data.Public.set(action.type, action.page, {scope: 'APP'}, undefined, undefined)
          }
        }
        break
      default:
        this.props.actions.updateSettings(action, this.props.state.component.id)
    }
  }

  onEditModeChange = () => {
    this.props.actions.setBaseEnvironment()
    this.props.actions.closeListLayoutItems()
  }

  onSitePublished = () => this.props.actions.updateComponent()

  render() {
    if (!this.props.state) {
      return <ErrorPage viewMode={this.props.host.viewMode} />
    }

    const {
      host: {viewMode, style},
      actions: {widgetLoaded},
      isRTL,
      pageInfo: {isHomePage},
      state,
      actions,
    } = this.props

    this.props.state.component.settings = {
      ...state.component.settings,
      ...style.styleParams.numbers,
      ...style.styleParams.booleans,
    }

    return (
      <RuntimeContext.Provider value={this.props}>
        <WidgetStateContext.Provider value={state}>
          <WidgetActionsContext.Provider value={actions}>
            <DatesProvider dates={this.props.state.dates}>
              <div
                id="wix-events-widget"
                className={classNames(s.root, {[s.buttonOnly]: isButtonLayout(this.props.state.component)})}
                dir={isRTL ? Direction.RTL : Direction.LTR}
                data-hook={DH.ROOT_NODE}
              >
                <AppLoaded
                  host={this.props.host}
                  widgetLoaded={widgetLoaded}
                  viewMode={viewMode as IWixWindowViewMode}
                  isHomePage={isHomePage}
                />
                <Layout />
              </div>
            </DatesProvider>
          </WidgetActionsContext.Provider>
        </WidgetStateContext.Provider>
      </RuntimeContext.Provider>
    )
  }
}
